import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import { Box } from '@mui/material'

interface Props {
  form: FormikProps<AddressFormInterface>,
}

const AccountAddressActions = ({ form }: Props) => {
  const { t } = useTranslations()
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()
  const theme = useCustomMuiTheme()

  const handleClick = () => {
    push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESSES}`))
  }

  return (
    <Box sx={{
      gridArea: 'actions',
      display: 'flex',
      gap: 2
    }}>
      <Button
        variant='contained'
        color='content'
        disableElevation
        loading={form.isSubmitting}
        type='submit'
        disabled={!form.dirty || !form.isValid}
        sx={{
          borderRadius: 'var(--btn-border-radius)',
          padding: '1em 3em',
          [theme.breakpoints.down('md')]: {
            flexGrow: 1
          }
        }}
      >
        {t('actions.save')}
      </Button>
      <Button
        color='secondary'
        variant='contained'
        disableElevation
        type='button'
        onClick={handleClick}
        sx={{
          borderRadius: 'var(--btn-border-radius)',
          padding: '1em 3em',
          [theme.breakpoints.down('md')]: {
            flexGrow: 1
          }
        }}
      >
        {t('actions.cancel')}
      </Button>
    </Box>
  )
}

export default AccountAddressActions
