import DescribedLabel from '~/src/components/utils/form/DecribedLabel'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import { FormikProps } from 'formik'
import { useEoValue } from '~/src/hooks/useEoState'
import { Country, Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useTranslations } from '~/src/hooks/useTranslations'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import FormikSelectField from '~/src/components/mui-wrappers/fields/FormikSelectField'

interface Props {
  form: FormikProps<AddressFormInterface>,
}

const AccountAddressFields = ({ form }: Props) => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { t } = useTranslations()

  return (
    <>
      <DescribedLabel
        gridArea='name'
        label={t('profile.address.address_name')}
        description={t('profile.address.address_name_description')}
      />
      <FormikTextField
        gridArea='name-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'name',
          label: t('profile.address.description'),
          size: 'small'
        }}
      />
      <DescribedLabel
        gridArea='address'
        label={t('profile.address.address')}
        description={t('profile.address.address_description')}
      />
      <FormikTextField
        gridArea='street-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'street',
          label: t('profile.address.street'),
          size: 'small'
        }}
      />
      <FormikTextField
        gridArea='houseNumber-field'
        TextFieldProps={{
          form,
          fullWidth: false,
          color: 'content',
          name: 'houseNumber',
          label: t('profile.address.house_number'),
          size: 'small'
        }}
      />
      <DescribedLabel
        gridArea='city'
        label={t('profile.address.locality')}
        description={t('profile.address.locality_description')}
      />
      <FormikTextField
        gridArea='locality-field'
        TextFieldProps={{
          form,
          name: 'locality',
          color: 'content',
          label: t('profile.address.locality'),
          size: 'small'
        }}
      />
      <FormikTextField
        gridArea='zipCode-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'zipCode',
          label: t('profile.address.zip_code'),
          size: 'small'
        }}
      />
      <DescribedLabel
        gridArea='country'
        label={t('profile.address.country')}
        description={t('profile.address.country_description')}
      />
      <FormikSelectField
        gridArea='country-field'
        SelectFieldProps={{
          form: form,
          color: 'content',
          name: 'country',
          label: t('profile.address.country'),
          size: 'small'
        }}
      >
        {firm?.countries?.map((country: Country) => (
          <option
            key={country.id}
            value={JSON.stringify(country)}
          >
            {country.name}
          </option>
        ))}
      </FormikSelectField>
    </>
  )
}

export default AccountAddressFields
